@import "../src/color.css";
@import url('https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,400;0,500;1,400&display=swap');

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body {
  font-family: serif;
}

.header-bg-color {
  background-color: var(--secondText);
}

.w-100 {
  width: 100%;
}

.form-bg {
  background-image: url(./images/first-dial.jpg);
  height: 100vh;
  background-size: cover;
  background-position: 50%;
}

.main-headings h3 {
  border-bottom: 2px solid;
  color: var(--secondColor);
}

/* //responsive design */
@media (min-width: 320px) and (max-width: 768px) {
  .dropdown button {
    margin-bottom: 10px;
    text-align: center;
  }

  .user-table {
    text-align: center;
  }
}

/* // my css  */
.navbar {
  font-family: system-ui;
}

html {
  overflow-x: hidden;
}

.login-form .input-group {
  height: 60px;
}

.login-form {
  font-family: system-ui;
}

.login-form .input-group-text {
  background-color: white !important;
}

.button-color {
  background-color: var(--secondColor);
  color: white;
  border: none !important;
}

.button-color:hover {
  background-color: #000215 !important;
  color: white !important;
}

.back-color {
  color: #eab512;
}

.bg-c-blue {
  background-color: var(--secondColor);
}

.bg-c-green {
  background-color: var(--themeMain);
}

.login-form button {
  background-color: var(--themeMain);
}

.min-height-60 {
  min-height: 160px;
}

.input-group .bi {
  color: var(--secondColor);
}

.navbar-nav .nav-item a {
  font-size: 16px;
  padding-left: 10px;
  color: white;
  text-decoration: none;
}

table,
.card {
  font-family: system-ui;
}

.text-align {
  text-align: -webkit-center;
}

.navbar-brand a {
  text-decoration: none !important;
  color: white;
}

.table-background {
  background-color: antiquewhite;
}

.form-width input {
  max-width: 40%;
}

.pagination {
  --bs-pagination-border-width: none !important;
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  background: #fff;
  padding: 8px;
  border-radius: 10px;
}

.align-center {
  align-items: center;
}

.card {
  --bs-card-border-width: none !important;
}

.card-body .list-group-item {
  border: none !important;
}

/* .table-background{
  background-color: #F1F5F7;
} */

.background-white {
  background-color: white;
}

.width-30 {
  width: 20%;
}

.ribbon-wrapper {
  width: 85px;
  height: 88px;
  overflow: hidden;
  position: absolute;
  top: 24px;
  left: 0;
}

.ribbon-wrapper .ribbons.warning {
  background-color: #ff1741;
}

.ribbon-wrapper .ribbons {
  font: 700 15px sans-serif;
  color: #333;
  text-align: center;
  -webkit-transform: rotate(-45deg);
  -moz-transform: rotate(-45deg);
  -ms-transform: rotate(-45deg);
  -o-transform: rotate(-45deg);
  position: relative;
  padding: 7px 0;
  top: 15px;
  left: -30px;
  width: 120px;
  color: #fff;
}

.custom-form .form-floating>.form-select {
  padding-top: 30px !important;
  height: calc(2.5rem + 2px) !important;
}

.bG-color {
  background: aliceblue;
}

.bg-white {
  background-color: white;
}

.box-shadow {
  box-shadow: rgba(60, 64, 67, 0.3) 0px 1px 2px 0px,
    rgba(60, 64, 67, 0.15) 0px 1px 3px 1px;
}

.submit-buttom {
  background-color: var(--themeMain);
  color: white;
}

.active>.page-link,
.page-link.active {
  z-index: 3;
  background-color: var(--themeMain);
  border-radius: 5px;
}

.table-img img {
  max-width: 30px;
}

.table-img {
  text-align: center;
}

@media (min-width: 320px) and (max-width: 600px) {
  .navbar-nav .nav-item .active {
    background: none !important;
    padding: 15px 18px 15px 18px;
    margin-left: 0px !important;
  }

  .mobile-center {
    text-align: center !important;
  }

  .mobile-margin {
    margin-top: 10px;
  }

  .form-width input {
    max-width: 100%;
    margin-bottom: 10px;
  }

  .form-check .form-check-input {
    margin-left: -7px;
  }

  .form-check .form-check-label {
    margin-left: 10px;
  }

  .list-unstyled .mb-2 {
    text-align: center;
  }
}

.user-table p {
  margin-bottom: 0px !important;
}

/* my new css */

.settings .bi {
  padding-left: 5px;
}

.menu-bar .bi {
  padding-right: 4px;
}


.menu-bar ul li {
  margin: 0px 10px 0px 10px;
}

.content a {
  text-decoration: none;
}

.btn:hover {
  background-color: var(--secondColor);
  color: white;
}

.navbar-nav .nav-item .active {
  background: var(--secondColor);
  padding: 15px;
  margin-left: 10px;
}

.align-baseline {
  align-items: baseline !important;
}

/* mobile-app css */


.header-background-color {
  background-color: var(--secondColor);
}

.header-background-color .nav-link a {
  color: var(--firstText);
}



.app-sidebaar.nav-item .nav-link .bi {
  color: var(--firstText);
}

.side-bar-img {
  max-width: 200px;
}



.offcanvas-body a {
  text-decoration: none;
}

.offcanvas-body {
  /* background-color: #F9F9F9; */
}


.offcanvas-body a {
  color: var(--themeMain);
  font-size: 16px;
  padding: 7px 10px;
  border-bottom: 1px solid;
  width: 100%;
  font-weight: 600;
}

.offcanvas-body a:hover {
  background: var(--themeMain);
  background-position: right bottom;
  transition: all .5s ease-out;
  color: #fff;
  border-radius: 0px 30px 30px 0px;
}

.offcanvas-body i {
  padding-right: 10px;
}

/*

.app-sidebaar .nav-item a,
i {
  color: rgb(0, 0, 0);
  font-size: 1.5rem;
  text-decoration: none;
}  

*/



.footer .nav-item {
  text-align: center;
}

.nav-item p {
  font-size: 15px;
  display: none;
}



.box-shadow {
  box-shadow: rgba(60, 64, 67, 0.3) 0px 1px 2px 0px,
    rgba(60, 64, 67, 0.15) 0px 1px 3px 1px;
}

.custom-margin {
  margin-top: 15%;
  margin-bottom: 15%;
}

.footer-bottom {
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  /* height: 50px; */
  z-index: 999;
}

.btn:hover {
  color: var(--bs-btn-hover-color);
  background-color: var(--bs-btn-hover-bg);
  border-color: white !important;
}

.offcanvas-header {
  background-color: var(--bs-black);
  color: var(--firstText);
  background-size: cover;
}

/* .offcanvas-body .nav-link .bi {
  color: var(--secondColor);
} */

.offcanvas-title button {
  color: white;
}

.fs-60 {
  font-size: 60px;
}

.background-blue {
  background-color: var(--firstText);
  color: var(--themeMain);
}

.font-small {
  font-size: small;
}

.yellow-color {
  color: #fcb700;
}

.yellow-bg {
  background-color: #fcb700;
}

.button-padding {
  padding-bottom: 15px;
  padding-top: 15px;
}

.fs-60 {
  font-size: 60px;
}

.max-width-30 {
  max-width: 17%;
}

.btn-color {
  background-color: var(--secondColor);
}

.text-color-brown {
  color: var(--secondColor);
}

.second-button {
  background-color: var(--secondColor);
  color: var(--firstText);
  border-radius: 10px;
}

/* .login-bg {
  background-image: url(../src/img/login-bg.jpg);
  background-position: center; 
  background-repeat: no-repeat; 
  background-size: cover;
} */

.bd-highlight img {
  max-width: 70px;
  height: 65px;
  border-radius: 10px;
}

.home .bi {
  color: var(--firstText);
}

.mg-o {
  margin-bottom: 0px;
}

.background-bl {
  background: lavender;
}

.hero-img img {
  width: 40px;
  height: auto;
}

.align-item {
  align-items: center;
}

.form img {
  width: 30px;
}

.category-button {
  font-size: 0.8rem;
}

.selected-bg {
  background-color: #000 !important;
  color: #fff !important;
}

.app-post-title {
  color: var(--secondText);
}

.modal-footer {
  border-top: none !important;
}

.news-img img {
  width: 100%;
  height: auto;
}

.subscription {
  list-style: none !important;
}

.news-button {
  background-color: var(--newsbg);
}

.subscription-img img {
  width: 500px;
}

/* .home-row .border .btn.active {
  background-color: #e70b0b;
}  */

.contact-img img {
  width: 80px;
}

.contact-content {
  padding-left: 20px;
}

.contact-icon .bi {
  color: var(--themeMain);
}

.align-profile-item {
  align-items: center !important;
}

.profile-main-img img {
  height: 75px;
}

@media only screen and (min-width: 600px) {
  .post-profile {
    padding: 9px !important;
  }

  .news-img img {
    height: 200px;
  }
}

.news-heading {
  text-decoration: none !important;
  color: #000;
}

.news-heading h6 {
  color: #000;
  font-weight: 800;
  font-size: 20px;
}

@media only screen and (max-width: 600px) {
  .offcanvas.offcanvas-start {
    width: 80%;
  }

  .f-11 {
    font-size: 11px;
  }

  .sub-foot {
    padding: 5px 0px !important;
    bottom: 0 !important;
    position: absolute !important;
    margin-bottom: 39px !important;
  }

  .sub-foot a {
    padding: 0px !important;
    text-decoration: none !important;
    font-size: 11px !important;
  }

  .main-contain-withoutfoot {
    margin-bottom: 13vh;
  }

  .mbl-btn-sml {
    font-size: 11px;
  }

  .pt-5-0 {
    padding: 4px 0px !important;
    height: 7vh;
  }

  .foot-img-43 {
    height: 8vh !important;
  }

  .date-font-size {
    font-size: 13px;
  }

  .mw-100 {
    width: 100% !important;
  }

  .mt-55 {
    margin-top: 60px !important;
  }

  .app-foot-plus {
    border: 1px solid;
    padding: 9px 12px;
    border-radius: 50%;
    font-size: 20px;
  }

  .app-foot-text {
    font-size: 10px;
  }

  .app-sab-menu {
    position: fixed;
    bottom: 47px;
    left: 0;
    right: 0;
    padding: 75px 20px 20px 20px;
    z-index: 999;
    background: var(--firstText);
    border-radius: 400px 400px 0px 0px;
    box-shadow: 0px 1px 3px 2px var(--secondColor);
    margin: auto;
    text-align: center;
  }

  /* profile */
  .app-width {
    width: 100%;
  }

  .app-logo {
    height: 38px;
  }

  .app-spacing-0 {
    padding: 0px;
  }

  .app-logo-big {
    height: 60px;
  }

  .app-post-title {
    margin-bottom: 0px !important;
  }

  .app-mb-0 {
    margin-bottom: 0px !important;
  }

  .add-post-btn {
    font-size: 19px !important;
  }

  .mbl-tabs {
    font-size: 13px;
    margin: 0px !important;
  }

  .category-button {
    font-size: 0.7rem;
  }

  .blog-details-img {
    width: 100%;
    height: auto;
  }

  .blog-content {
    padding: 10px !important;
  }

  .phone-hide {
    display: none;
  }

  .contact-details img {
    width: 60px;
    margin: auto;
  }

  .carousel-item img {
    width: 100%;
    height: auto !important;
  }

  /* .news-img img {
    width: 90px;
    border-radius: 10px;
    border: 1px solid;
  } */
}

.login-bg .row,
.sign-up {
  justify-content: center;
  overflow: hidden !important;
}

.carousel-item img {
  width: 100%;
  height: 400px;
}

.modal-header {
  border-bottom: none;
}

.whtsapp-button {
  background: #643f19;
  padding: 10px;
  border-radius: 5px;
  color: white;
  font-size: 15px;
}

.contact-details img {
  width: 90px;
  margin: auto;
}

.location .bi {
  font-size: 18px;
}

.contact-details ul li {
  padding: 10px;
}

.login-bg .row {
  --bs-gutter-x: 0rem !important;
}

.sign-up .sign-up {
  --bs-gutter-x: 0rem !important;
}


.about-main-img {
  width: 100%;
  height: auto;
}

.bg-about {
  background-color: var(--secondColor);
}

.about-main-cotent {
  background-color: var(--aboutbg);
}

.mbl-menu-btn {
  background-color: var(--secondText) !important;
  border: none;
  color: var(--firstText);
  font-size: 25px;
}

.about-img {
  background-image: url(./images/about-banner.jpg);
  background-position: center 0;
  background-repeat: no-repeat;
  background-size: cover;
}

.about-content {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.blog-content {
  padding: 40px;
}

.footer-ul {
  margin-bottom: 0% !important;
  padding: 10px 0px 10px 0px;
  /* margin-top: 2rem !important; */
}

.footer-bottom i {
  font-size: 1.3rem;
}


.order-button {
  padding: 10px 40px 10px 40px;
}

.footer-link a {
  color: var(--firstText);
}

.profile-order-button {
  padding: 0.5rem 1rem 0.5rem 1rem;
}

.text-black {
  color: var(--secondText) !important;
}

.homecard {
  background-color: var(--firstText);
  cursor: pointer;
}

.float-right {
  float: right;
}

.add-post-btn {
  color: green;
  font-size: 27px;
}

.desktop-menu-main a {
  color: var(--firstText);
  text-decoration: none;
  padding: 0px 13px 0px 0px;
  font-size: 14px;
}

.desktop-menu-main {
  align-items: center;
}

.card-text-wrap {
  word-wrap: break-word !important;
}

.btn-cursor {
  cursor: pointer;
}

.post-tabs {
  background-color: var(--secondText);
  color: var(--firstText);
  cursor: pointer;
}

.tabs-blue-border {
  border: 1px solid var(--secondColor) !important;
}

.desktop-logotitle {
  color: var(--firstText);
  text-decoration: none;
  font-size: 20px;
}

/* extra */
.css-13cymwt-control {
  border: none !important;
}


.social-links li {
  color: var(--themeMain);
}

.footer-link {
  text-align: center;
  line-height: 17px;
}

.botom-border-0 {
  text-decoration: none !important;
}

.pr-like-dislike {
  padding-right: 15px;
}

.single-post-user {
  border-radius: 100%;
  border: 1px solid var(--themeMain);
  padding: 6px;
  height: 50px;
  width: 50px;
}

.black-color {
  color: var(--secondText);
}

.cursor-pointer {
  cursor: pointer;
}

.selectedColor {
  background-color: var(--newsbg) !important;
}

.maintheme-bg {
  background-color: var(--themeMain);
}

.border-none {
  border: none !important;
}

.tbl-scrolling {
  overflow: auto;
}

/* .bootom-img-nav img{
height: 25px;
} */
.post-profile {
  padding: 0px;
}

.post-profile img {
  height: 50px;
  width: 50px;
  border-radius: 50%;
  border: 2px solid var(--themeMain);
  border-radius: 100%;
  padding: 3px;
  text-align: center;
}

.gen-write-herepost {
  border-radius: 19px;
  background: #fff !important;
}

.post-btn-read-more {
  background: var(--themeMain);
  border: none;
  font-size: 16px;
  border-radius: 8px 0px;
  box-shadow: 0px 2px 2px 0px var(--themeMain);
}

.w-70 {
  width: 70%;
}

.plus-tab-mbl-menu img {
  margin-top: -3vh;
  border-radius: 50%;
  /* border: 2px solid var(--themeMain); */
  background: var(--secondText);
}

.desktop-icons-img img {
  background: #fff;
  border-radius: 50%;
  padding: 1px;
  height: 21px;
}

.foot-img-icon img {
  height: 4.5vh;
}

/* animation */
.rotate {
  animation: loading 10s linear infinite;
}

@keyframes loading {
  0% {
    transform: rotate(0);
  }

  100% {
    transform: rotate(360deg);
  }
}

.custome-input-text input[type=file]::-webkit-file-upload-button {
  display: none;
}

.desktop-home-view {
  height: 70vh;
  overflow: auto;
}

.sub-foot {
  background: var(--secondColor);
  text-align: center;
  padding: 10px 0px !important;
  width: 100%;
}

.sub-foot a {
  padding: 0px 20px;
  text-decoration: none;
  color: #fff;
  font-size: 14px;
}

.client-wellcome {
  color: var(--themeMain);
}

.client-wellcome h4 {
  font-weight: bold;
}

.brown-bg {
  background-color: var(--secondColor);
}

.blink-text {
  animation: textblink 1.0s linear infinite;
}

@keyframes textblink {
  0% {
    opacity: 0;
  }

  50% {
    opacity: 0.5;
  }

  100% {
    opacity: 1;
  }
}

.main-about-content h5 {
  color: #fff;
  font-weight: 600;
  border-bottom: 2px solid #000;
  border-radius: 0px 0px 19px 19px;
  background-image: linear-gradient(to right, var(--themeMain), var(--secondColor));
  padding: 4px;
}

.wrong-icon {
  font-weight: bold;
  font-size: 31px;
}

.right-icon {
  color: #16d816;
  font-weight: bold;
  font-size: 31px;
}

.app-header-bottom a {
  font-size: 12px;
  padding: 5px;
  text-decoration: none;
  color: var(--themeMain);
  font-weight: 900;
}

.sub-menu-desk a {
  color: var(--themeMain) !important;
  font-weight: 600;
}

.side-bar-foot {
  line-height: 10px;
}

.b-r-50 {
  border-radius: 50px;
}

.cursore-pointer {
  cursor: pointer;
}

.font-weight-bold {
  font-weight: 700;
}

.footbox {
  height: 100vh;
}

.market-mbl h6 {
  font-size: 13px;
}

.market-mbl-up-down {
  font-size: 15px;
}

.referral-danger {
  background: #dc3545;
  color: #fff;
  font-weight: 600;
  padding: 3px 6px 5px;
  border-radius: 13px;
}

.referral-success {
  background: #198754;
  color: #fff;
  font-weight: 600;
  padding: 3px 6px 5px;
  border-radius: 13px;
}

.h-100vh {
  height: 100vh;
}

.selected-strip {
  text-align: center;
  padding: 5px 0px;
  background: #0f203a;
  color: #fff;
  margin-top: 8px;
  box-shadow: 3px 1px 6px 1px #0f203a;
}

/* payment */
.payment-resp {
  height: 100vh;
  height: 100vh;
  align-items: center;
  text-align: center;
  color: #fff;
}